<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3"
          y="7"
          width="12"
          height="9"
          rx="1.5"
          stroke="#9e9e9e"
          stroke-width="2"
    />
    <path d="M6 6.5V5a3 3 0 013-3v0a3 3 0 013 3v1.5" stroke="#9e9e9e" stroke-width="2" />
    <path d="M9 11v1"
          stroke="#9e9e9e"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
    />
  </svg>
</template>

<script>
    export default {
        name: "Password"
    }
</script>
